import React, { useState, createContext, ReactNode } from 'react'

interface MenuContextProps {
    isOpen: boolean
    toggleNav: () => void
}

const MenuContext = createContext<MenuContextProps>({
    isOpen: false,
    toggleNav: () => {},
})

interface MenuProviderProps {
    children: ReactNode
}

export const MenuProvider = ({ children }: MenuProviderProps) => {
    const [isOpen, setNav] = useState(false)

    const toggleNav = () => {
        setNav(!isOpen)
    }

    return (
        <MenuContext.Provider value={{ isOpen, toggleNav }}>
            {children}
        </MenuContext.Provider>
    )
}

export default MenuContext
