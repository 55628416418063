import React, { ReactNode, useEffect } from 'react'
import { MenuProvider } from './contexts/MenuContext'

interface RootElementProps {
    children: ReactNode
}

const RootElement: React.FC<RootElementProps> = ({ children }) => {
    useEffect(() => {
        let viewport = document.querySelector(`meta[name='viewport']`)
        if (viewport) {
            if (viewport.getAttribute('content')?.includes('minimum-scale=1')) return
            viewport.setAttribute('content', viewport.getAttribute('content') + ', minimum-scale=1')
        }
    }, [])

    return <MenuProvider>{children}</MenuProvider>
}

export default RootElement