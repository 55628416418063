import React, { ReactElement } from 'react'
import { AnimatePresence } from 'framer-motion'
import '@fontsource/heebo/400.css'
import '@fontsource/heebo/700.css'

import './GlobalStyles.css'
import Root from './src/Root'

interface WrapProps {
    element: ReactElement
}

export function wrapPageElement({ element }: WrapProps): ReactElement {
    return <AnimatePresence mode='wait'>{element}</AnimatePresence>;
}

export function wrapRootElement({ element }: WrapProps): ReactElement {
    return <Root>{element}</Root>
}